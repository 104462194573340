/*****************************************************************
 ***
 ***  FotoFinder Hub V2.0
 ***  Copyright (C) 2012 - 2020 FotoFinder Systems GmbH
 ***
 ***  Last update: 21.07.20.stp
 ***
 *****************************************************************/


import Modal from "../base_modal";

export default class extends Modal {
  static targets = ["container", "trial", "starter", "plus", "pro", "enterprise",
                    "submitBtn", "submitDiv", "deleteDialog", "stripeInfo",
                    "switchLanguage", "switchAccount", "stripeUserDetail",
                    "customerTitle", "subscriptionTitle", "xBtn", "closeBtn"];

  show(event) {
    super.open(event);
    const value = event.target.dataset.value;
    this.operateModal(value, "show");
    this.loadStripeDetail(event.target, value);
  }

  showDeleteDialog(event) {
    super.open(event)
    this.deleteDialogTarget.classList.remove("hidden")
  }

  //
  // Enables the button "Cancel Plan"
  //
  enableCancelPlan(event) {
    event.preventDefault();
    this.submitDivTarget.classList.add("hidden");
    this.submitBtnTarget.classList.remove("hidden");
  }

  close(event) {
    if(event) {
      event.preventDefault();
      this.operateModal(event.target.dataset.value, "hide");
      super.close(event);
    }
  }

  closeBackground(event) {
    this.operateModal(event.target.dataset.value, "hide");
    this.removeDataValueFromMainContainer();
    super.closeBackground(event);
  }

  closeWithKeyboard(event) {
    this.operateModal(this.containerTarget.dataset.value, "hide");
    this.removeDataValueFromMainContainer();
    super.closeWithKeyboard(event);
  }

  operateModal(target_code, action) {
    switch(target_code) {
      case "trial":
        action === "hide" ? this.addToggleClass(this.trialTarget) : this.removeToggleClass(this.trialTarget);
        break;
      case "starter":
        action === "hide" ? this.addToggleClass(this.starterTarget) : this.removeToggleClass(this.starterTarget);
        break;
      case "plus":
        action === "hide" ? this.addToggleClass(this.plusTarget) : this.removeToggleClass(this.plusTarget);
        break;
      case "pro":
        action === "hide" ? this.addToggleClass(this.proTarget) : this.removeToggleClass(this.proTarget);
        break;
      case "enterprise":
        action === "hide" ? this.addToggleClass(this.enterpriseTarget) : this.removeToggleClass(this.enterpriseTarget);
        break;
      case "stripe_admin_panel":
      case "clinic_subscription_info":
      case "user_subscription_info":
        this.setupStripeInfoModal(action, target_code);
        break;
      case "switch_language":
        action === "hide" ? this.addToggleClass(this.switchLanguageTarget) : this.removeToggleClass(this.switchLanguageTarget);
        // switch language and acc share the same parent container
        // the parent has to be marked so when it is closed by clicking the background we can also hide the child container
        this.addDataValueToMainContainer(target_code);
        break;
      case "switch_acc":
        action === "hide" ? this.addToggleClass(this.switchAccountTarget) : this.removeToggleClass(this.switchAccountTarget);
        this.addDataValueToMainContainer(target_code);
        break;
      default:
        // nothing to do
        break;
    }
  }

  addDataValueToMainContainer(target_code) {
    this.containerTarget.setAttribute("data-value", target_code);
  }

  removeDataValueFromMainContainer() {
    this.containerTarget.removeAttribute("data-value");
  }

  removeToggleClass(target) {
    target.classList.remove(this.toggleClass);
  }

  addToggleClass(target) {
    target.classList.add(this.toggleClass)
  }

  setupStripeInfoModal(action, target_name) {
    if (action === "hide") {
      this.addToggleClass(this.stripeInfoTarget);
      this.subscriptionTitleTarget.classList.add("hidden");
    } else {
      this.removeToggleClass(this.stripeInfoTarget);
      this.subscriptionTitleTarget.classList.remove("hidden");
      this.xBtnTarget.dataset.value = target_name;
      this.closeBtnTarget.dataset.value = target_name;
    }
  }

  loadStripeDetail(target, info_type) {
    let url;
    switch(info_type) {
      case "stripe_admin_panel":
        url = `/admin/users/${target.dataset.userId}/subscription_histories/fetch_stripe_customer?stripe_customer_id=${target.text}`;
        break;
      case "clinic_subscription_info":
        url = `/admin/clinics/${target.dataset.clinicId}/clinic_subscriptions/${target.dataset.subscriptionId}?history=${target.dataset.subscriptionHistory}`;
        break;
      case "user_subscription_info":
        url = `/admin/users/${target.dataset.userId}/subscription_histories/${target.dataset.subscriptionId}?history=${target.dataset.subscriptionHistory}`;
        break;
      default:
        url = undefined;
        break;
    }

    if (url) {
      fetch(url).then(response => response.json())
                .then(response => {
                  this.stripeUserDetailTarget.innerHTML = JSON.stringify(response, null, 2);
                })
                .catch(error => { return error; });
    }
  }
}
